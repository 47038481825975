.c-icon {
  display: inline-block;
  color: inherit;
  text-align: center;
  fill: currentColor;
  @include icon-size($icon-size-base);
}

// TODO: create variable with a range
@for $i from 2 through 9 {
  .c-icon-#{$i}xl {
    @include icon-size($i * ($icon-size-xl / 1.5));
  }
}

.c-icon-xl {
  @include icon-size($icon-size-xl);
}

.c-icon-lg {
  @include icon-size($icon-size-lg);
}

.c-icon-sm {
  @include icon-size($icon-size-sm);
}

// TODO: Consider whether to add an alias `.c-icon-c-size` or shorten the class name.
.c-icon-c-s,
.c-icon-custom-size {
  // stylelint-disable-next-line declaration-no-important
  width: initial !important;
  // stylelint-disable-next-line declaration-no-important
  height: initial !important;
}
