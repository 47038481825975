

// Code

$code-font-size:            87.5% !default;
$code-color:                $pink !default;

$kbd-padding-y:             .2rem !default;
$kbd-padding-x:             .4rem !default;
$kbd-font-size:             $code-font-size !default;
$kbd-color:                 $white !default;
$kbd-bg:                    $gray-900 !default;

$pre-color:                 $gray-900 !default;
$pre-scrollable-max-height: 340px !default;
