// Toasts

$toast-max-width:               350px !default;
$toast-padding-x:               .75rem !default;
$toast-padding-y:               .25rem !default;
$toast-font-size:               .875rem !default;
$toast-color:                   null !default;
$toast-background-color:        rgba($white, .85) !default;
$toast-border-width:            1px !default;
$toast-border-color:            rgba($black, .1) !default;
$toast-border-radius:           .25rem !default;
$toast-box-shadow:              0 .25rem .75rem rgba($black, .1) !default;

$toast-header-color:            $gray-600 !default;
$toast-header-background-color: rgba($white, .85) !default;
$toast-header-border-color:     rgba($black, .05) !default;
