// Lists

// Unstyled keeps list items block level, just removes default browser padding and list-style
@mixin list-unstyled {
  @include ltr {
    padding-left: 0; // reset padding because ul and ol
  }
  @include rtl {
    padding-right: 0; // reset padding because ul and ol
  }
  list-style: none;
}
