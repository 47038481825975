//
// CoreUI default layout
//

.c-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

.c-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .c-header-fixed{
    margin: inherit;
  }
}

// if header on top
.c-header {
  ~ .c-sidebar {
    margin-top: $header-height;
  }

  &.c-header-fixed ~ .c-wrapper {
    margin-top: $header-height;
  }

  &.c-header-with-subheader {
    ~ .c-sidebar {
      margin-top: $header-height + $header-subheader-height;
    }
    &.c-header-fixed ~ .c-wrapper {
      margin-top: $header-height + $header-subheader-height;
    }
  }
}

.c-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.c-main {
  flex: 1;
  min-width: 0;
  padding-top: 2 * $spacer;

  > .container-fluid {
    @include media-breakpoint-up(md) {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }
  }
}

.c-header-fixed {
  ~ .c-body {
    padding-top: $header-height;
  }

  &.c-header-with-subheader ~ .c-body {
    padding-top: $header-height + $header-subheader-height;
  }
}
