// stylelint-disable declaration-no-important

// Contextual backgrounds

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $color) {
  #{$parent} {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  }
}

@mixin coreui-bg-gradient-variant($parent, $colors) {
  #{$parent} {
    background: map-get($colors, "stop");
    background: linear-gradient(45deg, map-get($colors, "start") 0%, map-get($colors, "stop") 100%);
    border-color: map-get($colors, "stop");
  }
}

@mixin coreui-bg-gradient-variant-dark-theme($parent, $colors) {
  #{$parent} {
    background: desaturate(map-get($colors, "stop"), 20%);
    background: linear-gradient(45deg, desaturate(map-get($colors, "start"), 20%) 0%, desaturate(map-get($colors, "stop"), 20%) 100%);
    border-color: desaturate(map-get($colors, "stop"), 20%);
  }
}
